import { isNull, isNullOrUndefined, isNumber, isObject, isString } from 'util';
import { OrderedMap } from 'immutable';


export class Datum {

  private datum: Date;

  public constructor(date: Date | number | string = new Date(), type?: string) {
    if (isNullOrUndefined(date)) {
      this.datum = new Date();
    } else if (isNumber(date)) {
      if (('' + date).length <= 10) {
        date = (<number> date) * 1000;
      }
      this.datum = new Date(date);
    } else if (isString(date)) {
      const dDate = date.match(/(\d+)/g);
      if (!isNullOrUndefined(type) && !isNullOrUndefined(dDate) && type === 'eu' && dDate.length === 3) {
        this.datum = new Date(`${dDate[1]}.${dDate[0]}.${dDate[2]}`);
      } else {
        this.datum = new Date('' + date);
      }
    } else if (isObject(date) && !isNullOrUndefined((<any> date).date)) {
      this.datum = new Date((<any> date).date);
    } else {
      this.datum = <Date> date;
    }
  }

  public clone(): Datum {
    return new Datum(this.timestamp);
  }

  public get date(): Date {
    return this.datum;
  }

  public set date(date: Date) {
    this.datum = date;
  }

  public get year(): number {
    return this.datum.getFullYear();
  }

  public get month(): number {
    return this.datum.getMonth() + 1;
  }

  public get week(): number {
    /* Script by WaKi Software GmbH */
    const currentThursday = new Date(this.date.getTime() + ( 3 - ((this.date.getDay() + 6) % 7)) * 86400000);
    const yearOfThursday = currentThursday.getFullYear();
    const firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + ( 3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000);
    return Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7);
  }

  public get quarter(): number {
    return Math.ceil((this.datum.getMonth() + 1) / 3);
  }

  public get day(): number {
    return this.datum.getDay();
  }

  public get daysInMonth () {
    return new Date(this.year, this.month, 0).getDate();
  }

  public get daysInYear() {
    return this.leapYear ? 366 : 365;
  }

  public get leapYear() {
    return this.year % 400 === 0 || (this.year % 100 !== 0 && this.year % 4 === 0);
  }

  public gt(datum: Datum): boolean {
    return this.timestamp > datum.timestamp;
  }

  public gteq(datum: Datum): boolean {
    return this.timestamp >= datum.timestamp;
  }

  public lt(datum: Datum): boolean {
    return this.timestamp < datum.timestamp;
  }

  public lteq(datum: Datum): boolean {
    return this.timestamp <= datum.timestamp;
  }

  public eq(datum: Datum): boolean {
    return this.timestamp === datum.timestamp;
  }

  public sameDay(datum: Datum): boolean {
    return datum.year === this.year && datum.month === this.month && datum.datum.getDate() === this.datum.getDate();
  }

  public beforeDay(datum: Datum): boolean {
    return datum.year >= this.year && datum.month >= this.month && datum.datum.getDate() > this.datum.getDate();
  }

  public afterDay(datum: Datum): boolean {
    return datum.year <= this.year && datum.month <= this.month && datum.datum.getDate() < this.datum.getDate();
  }

  public setDay() {
    this.date.setHours(0);
    this.date.setMinutes(0);
    this.date.setSeconds(0);
    this.date.setMilliseconds(0);
    return this;
  }

  public setEndOfDay() {
    this.addDays(1).setDay().addSeconds(-1);
    return this;
  }

  public setStartOfMonth() {
    this.setDay();
    this.setDate(1);
    return this;
  }

  public setEndOfMonth() {
    this.setStartOfMonth().addMonths(1).addDays(-1);
    this.setEndOfDay();
    return this;
  }

  public setToDayInNextWeek(day = 1) {
    const distance = (day + 7 - this.datum.getDay()) % 7;
    this.date.setDate(this.datum.getDate() + distance);
    return this.setDay();
  }

  public setToDayInCurrentWeek(day = 6) {
    const distance = day - this.datum.getDay();
    this.date.setDate(this.datum.getDate() + distance);
    return this.setDay();
  }

  public setYear(year: number) {
    this.date.setFullYear(year);
    return this;
  }

  public setQuarter(quarter: number) {
    if (quarter > 4) {
      const years = Math.floor(quarter / 4);
      this.addYears(years);
      quarter = quarter - (years * 4);
      if (quarter === 0) {
        quarter = 4;
      }
    }
    switch (quarter) {
      case 1:
        this.date.setMonth(0);
        break;
      case 2:
        this.date.setMonth(3);
        break;
      case 3:
        this.date.setMonth(6);
        break;
      case 4:
        this.date.setMonth(9);
        break;
    }
    return this;
  }

  public setMonth(month: number) {
    this.date.setMonth(month);
    return this;
  }

  public setDate(date: number) {
    this.date.setDate(date);
    return this;
  }

  public setHours(hours: number) {
    this.date.setHours(hours);
    return this;
  }

  public setMinutes(minutes: number) {
    this.date.setMinutes(minutes);
    return this;
  }

  public setSeconds(seconds: number) {
    this.date.setSeconds(seconds);
    return this;
  }

  public nextDay() {
    this.datum = new Date((this.timestamp + 86400) * 1000);
    return this;
  }

  public prevDay() {
    this.datum = new Date((this.timestamp - 86400) * 1000);
    return this;
  }

  public nextYear(): Datum {
    const _date = new Date(this.date.valueOf());
    _date.setFullYear(this.year + 1);
    return new Datum(_date);
  }

  public nextYears(count: number, self = false, date: Datum = this, result = []) {
    if (self) {
      result.push(date.year);
    }
    if (count === 0) {
      return result;
    }
    return this.nextYears(--count, true, date.nextYear(), result);
  }

  public weeksMap(until: Datum, businessDays = false) {
    let weeksMap = OrderedMap<string, number>();
    let d = new Datum(this.date);
    while (d.lt(until)) {
      const val = weeksMap.has(d.year + '-' + d.week) ? weeksMap.get(d.year + '-' + d.week) : 0;
      if (!businessDays || (businessDays && d.day !== 0 && d.day !== 6)) {
        weeksMap = weeksMap.set(d.year + '-' + d.week, val + 1);
      } else {
        weeksMap = weeksMap.set(d.year + '-' + d.week, val + 0);
      }
      d = d.addDays(1);
    }
    return weeksMap;
  }

  public daysDiff(second: Datum, businessDays = false) {
    let diff = second.timestamp - this.timestamp;
    if (businessDays) {
      diff = diff - (this.getWeekendDays(second) * 86400);
    }
    return Math.round(diff / 86400);
  }

  public validDaysDiff(second: Datum, validFn = (d: Datum) => true) {
    const firstDay = this.clone().setDay();
    const secondDay = second.clone().setDay();
    let validDays = 0;
    if (firstDay.eq(secondDay)) {
      return 0;
    }
    secondDay.addDays(1);
    while (firstDay.beforeDay(secondDay)) {
      if (validFn(firstDay)) {
        validDays++;
      }
      firstDay.addDays(1);
    }
    return validDays;
  }

  public monthsDiff(second: Datum) {
    let months = (second.date.getFullYear() - this.date.getFullYear()) * 12;
    months += second.date.getMonth() - this.date.getMonth();
    return Math.abs(months);
  }

  public getWeekendDays(second: Datum) {
    let d = new Datum(this.date);
    let result = 0;
    while (d.lt(second)) {
      d = d.addDays(1);
      if (d.day === 0 || d.day === 6) {
        result++;
      }
    }
    return result;
  }

  public monthDifferenceIsLower(count: number, second: Datum): boolean {
    return this.addMonths(count).gt(second);
  }

  public getDayOfWeek(kw: number, year: number) {
    return new Datum('1/2/' + year).addWeeks(kw - 1);
  }

  public getMonthCount(datum: Datum, addCurrent = false) {
    return (datum.year - this.year) * 12 + (datum.month - this.month) + (addCurrent ? 1 : 0);
  }

  public addMonths(months: number, virtual = false): Datum {
    const d = virtual ? new Date(this.date.valueOf()) : this.date;
    d.setMonth(d.getMonth() + months);
    return virtual ? new Datum(d) : this;
  }

  public addYears(years: number): Datum {
    const year = this.year;
    this.date.setFullYear(year + years);
    return this;
  }

  public addWeeks(weeks: number): Datum {
    this.date = new Date((this.timestamp * 1000) + (weeks * 604800000));
    return this;
  }

  public addDays(days: number): Datum {
    this.date = new Date((this.timestamp * 1000) + (days * 86400000));
    return this;
  }

  public addHours(hours: number): Datum {
    this.date = new Date(((this.timestamp + (hours * 3600)) * 1000));
    return this;
  }

  public addSeconds(seconds: number): Datum {
    this.date = new Date(((this.timestamp + (seconds * 60)) * 1000));
    return this;
  }

  public addWorkingDays(days: number): Datum {
    const date = this.clone();
    if (days > 0) {
      for (let i = 0; i < days; i++) {
        date.addDays(1);
        const day = date.date.getDay();
        if (day === 0) {
          date.addDays(1);
        } else if (day === 6) {
          date.addDays(2);
        }
      }
    } else {
      for (let i = days; i < 0; i++) {
        date.addDays(-1);
        const day = date.date.getDay();
        if (day === 0) {
          date.addDays(-1);
        } else if (day === 6) {
          date.addDays(-2);
        }
      }
    }
    this.date = date.date;
    return this;
  }

  public addOffset(): Datum {
    this.date = new Date((this.timestamp * 1000) + (-this.date.getTimezoneOffset() * 60000));
    return this;
  }

  public fromTimeString(timeString) {
    if (isNumber(timeString)) {
      this.datum = new Date(timeString * 1000);
      return this;
    }
    if (timeString === '') {
      return this;
    }
    if (!isNullOrUndefined(timeString)) {
      let matches = timeString.match(/(\d*)-(\d*)-(\d*)T(\d*):(\d*):(\d*)/);
      if (isNull(matches)) {
        matches = timeString.match(/(\d*)-(\d*)-(\d*) (\d*):(\d*):(\d*)/);
      }
      if (!isNull(matches)) {
        this.datum = new Date(parseInt(matches[1]), (parseInt(matches[2]) - 1), parseInt(matches[3]), parseInt(matches[4]), parseInt(matches[5]), parseInt(matches[6]));
      }
    }
    return this;
  }

  public get timestamp() {
    return parseInt('' + (this.datum.valueOf() / 1000));
  }

  public get utc() {
    const timezone = (new Date().getTimezoneOffset()) / 60;
    return parseInt('' + ( (this.datum.valueOf() - (timezone * 3600000)) / 1000));
  }

  public toString(format?: string) {
    switch (format) {
      case 'Y':
        return this.datum.getFullYear();
      case 'MY':
        return ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-' + this.datum.getFullYear();
      default:
        return this.datum.getFullYear() + '-' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-' + (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate() + ' ' +
          (this.datum.getHours() < 10 ? '0' : '') + this.datum.getHours() + ':' + (this.datum.getMinutes() < 10 ? '0' : '') + this.datum.getMinutes() + ':' + (this.datum.getSeconds() < 10 ? '0' : '') + this.datum.getSeconds();
    }
  }

  public toISOString(addOffset = false) {
    if (addOffset) {
      this.datum.setHours(this.datum.getHours() - (this.date.getTimezoneOffset() / 60));
    }
    return this.datum.getFullYear() + '-' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-' + (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate() + 'T' +
      (this.datum.getHours() < 10 ? '0' : '') + this.datum.getHours() + ':' + (this.datum.getMinutes() < 10 ? '0' : '') + this.datum.getMinutes() + ':' + (this.datum.getSeconds() < 10 ? '0' : '') + this.datum.getSeconds();
  }

  public toSortableString() {
    return this.datum.getFullYear() + '-' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-' + (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate() + 'T' +
      (this.datum.getHours() < 10 ? '0' : '') + this.datum.getHours() + ':' + (this.datum.getMinutes() < 10 ? '0' : '') + this.datum.getMinutes() + ':' + (this.datum.getSeconds() < 10 ? '0' : '') + this.datum.getSeconds() + 'Z';
  }

  public toEuropeanString() {
    return (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate() + '.' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '.' + this.datum.getFullYear() + ' ' +
      (this.datum.getHours() < 10 ? '0' : '') + this.datum.getHours() + ':' + (this.datum.getMinutes() < 10 ? '0' : '') + this.datum.getMinutes();
  }

  public toDateString() {
    return this.datum.getFullYear() + '-' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-' + (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate();
  }

  public toGoLang(ignoreOffset = false) {
    // 2006-01-02T15:04:05Z07:00
    const offset = ignoreOffset ? 0 : (-this.date.getTimezoneOffset() / 60);
    let date = this.datum.getDate();
    let hours = this.datum.getHours() - offset;
    if (hours < 0) {
      if (date > 1) {
        date -= 1;
      }
      hours = 24 - offset;
    }
    return this.datum.getFullYear() + '-' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-' + (date < 10 ? '0' : '') + date + 'T' +
      ((hours) < 10 ? '0' : '') + (hours) + ':' + (this.datum.getMinutes() < 10 ? '0' : '') + this.datum.getMinutes() + ':' + (this.datum.getSeconds() < 10 ? '0' : '') + this.datum.getSeconds() +
      'Z';
  }

  public toEuropeanDateString() {
    return (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate() + '.' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '.' + this.datum.getFullYear();
  }

  public toEuropeanMonthString() {
    return ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '.' + this.datum.getFullYear();
  }

  public toFileNameString() {
    return this.datum.getFullYear() + '_' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '_' + (this.datum.getDate() < 10 ? '0' : '') + this.datum.getDate() + '_' +
      (this.datum.getHours() < 10 ? '0' : '') + this.datum.getHours() + '_' + (this.datum.getMinutes() < 10 ? '0' : '') + this.datum.getMinutes() + '_' + (this.datum.getSeconds() < 10 ? '0' : '') + this.datum.getSeconds();
  }

  public toBucketString() { // YYYY-MM-DD
    return this.datum.getFullYear() + '-' + ((this.datum.getMonth() + 1) < 10 ? '0' : '') + (this.datum.getMonth() + 1) + '-01';
  }

  public toMonthDayKey() {
    return this.month + '/' + this.date;
  }

  public getMonthText() {
    switch (this.datum.getMonth() + 1) {
      case 1: return 'DATE.JANUARY';
      case 2: return 'DATE.FEBRUARY';
      case 3: return 'DATE.MARCH';
      case 4: return 'DATE.APRIL';
      case 5: return 'DATE.MAY';
      case 6: return 'DATE.JUNE';
      case 7: return 'DATE.JULY';
      case 8: return 'DATE.AUGUST';
      case 9: return 'DATE.SEPTEMBER';
      case 10: return 'DATE.OCTOBER';
      case 11: return 'DATE.NOVEMBER';
      case 12: return 'DATE.DECEMBER';
    }
    return '';
  }

  public getMonthAbbreviationText() {
    switch (this.datum.getMonth() + 1) {
      case 1: return 'DATE.ABBREVIATION.JANUARY';
      case 2: return 'DATE.ABBREVIATION.FEBRUARY';
      case 3: return 'DATE.ABBREVIATION.MARCH';
      case 4: return 'DATE.ABBREVIATION.APRIL';
      case 5: return 'DATE.ABBREVIATION.MAY';
      case 6: return 'DATE.ABBREVIATION.JUNE';
      case 7: return 'DATE.ABBREVIATION.JULY';
      case 8: return 'DATE.ABBREVIATION.AUGUST';
      case 9: return 'DATE.ABBREVIATION.SEPTEMBER';
      case 10: return 'DATE.ABBREVIATION.OCTOBER';
      case 11: return 'DATE.ABBREVIATION.NOVEMBER';
      case 12: return 'DATE.ABBREVIATION.DECEMBER';
    }
    return '';
  }

  public toBitrix() {
    this.toLocalTimezone(1).toString();
  }

  public toTimelineId(): number {
    this.datum.setHours(12, 0, 0, 0);
    return this.timestamp;
  }

  public resetTimezone(): Datum {
    return this.addHours(this.date.getTimezoneOffset() / 60);
  }

  public toLocalTimezone(timezone: number = (new Date().getTimezoneOffset()) / 60) {
    this.datum = new Date(this.datum.valueOf() + (timezone * 3600000));
    return this;
  }

  public toPastTime() {
    const now = new Datum();
    const diff = now.timestamp - this.timestamp;
    /* Check if the diff is above days */
    if (diff > 86400) {
      return { date: this.toEuropeanDateString(), text: '' };
    } else if (diff > 3600) {
      return { date: Math.floor(diff / 3600), text: 'DATE.PAST.HOURS' };
    } else if (diff > 60) {
      return { date: Math.floor(diff / 60), text: 'DATE.PAST.MINUTES' };
    } else {
      return { date: '', text: 'DATE.PAST.JUSTNOW' };
    }
    // let diff = second.timestamp - this.timestamp;
    // if (businessDays) {
    //   diff = diff - (this.getWeekendDays(second) * 86400);
    // }
    // return Math.round(diff / 86400);
  }

}
